import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from './common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "дизайн-система-вшэ"
    }}>{`Дизайн-система ВШЭ`}</h1>
    <h2 {...{
      "id": "что-такое-дизайн-система"
    }}>{`Что такое дизайн-система?`}</h2>
    <p>{`Дизайн-система - это набор компонентов, инструментов и процессов, которые упростят создание, тестирование,
визуальное и техническое обновление ИТ-продуктов Высшей Школы Экономики, а также обеспечат единообразие их интерфейсов.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Единообразие`}</strong>{`. Дизайн-система обеспечивает единый визуальный стиль и принципы работы интерфейса.
Продукты, реализованные с использованием Дизайн-системы воспринимаются как часть единого целого.
Они работают одинаково, понятно и привычно.
Это повышает узнаваемость бренда и облегчает жизнь пользователю.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Экономия времени`}</strong>{`. Благодаря тому, что большинство стандартных компонентов уже спроектировано и реализовано,
дизайнер продукта может сосредоточиться на бизнес-логике своего продукта и своих особенных компонентах.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Удобство`}</strong>{`. Готовые компоненты удобно использовать через библиотеку Figma или наши кодовые библиотеки для React и Vue.
Также вы можете сразу использовать компоненты ДС вместо абстрактных вайрфреймов для проектирования интерфейса.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Гибкость`}</strong>{`. Мы разрабатываем компоненты расширяемо, чтобы оставить свободу дизайнерам и разработчикам продукта.
Вы можете комбинировать наши компоненты с особенными компонентами вашего продукта, чтобы создавать тот UX,
который нужен именно вам.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "принципы-дизайна-цифровых-продуктов-ниу-вшэ"
    }}>{`Принципы дизайна цифровых продуктов НИУ ВШЭ`}</h2>
    <p>{`Сервисами и приложениями ВШЭ пользуются самые разные люди. Все они: разных возрастов, с разным уровнем компьютерной грамотности, и с разной степенью возможностью взаимодействия с интерфейсом.`}</p>
    <p>{`Чтобы обеспечить большинству пользователей приятный опыт взаимодействия с продуктами ВШЭ, интерфейсы должны соблюдать следующие принципы:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Контрастность.`}</strong>{` Текст в интерфейсе должно быть приятно читать. Рекомендуемая контрастность для текста: 4.5:1. Важные элементы на экране, такие как: кнопки, ссылки, элементы управления и другие активные элементы должны быть достаточно заметны, чтобы управлять вниманием пользователя и снижать когнитивную нагрузку.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Управляемость.`}</strong>{` Пользователь должен мочь полноценно пользоваться интерфейсом слюбого устройства: клавиатура, мышь, тачпад и т.д. Для этого контролы и активные элементы интерфейса должны иметь хорошо различимые и проработанные состояния. Отдельно стоит сказать про фокусное состояние. Это помогает пользователю восстановить контекст, если он потерялся или отвлекся.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Удобность.`}</strong>{` Важные элементы интерфейса нужно делать такими, чтобы их  можно было просто и быстро нажать. Иконки, пункты меню и другие мелкие активные элементы интерфейса должны иметь область клика больше, чем они сами, для упрощения наведения и клика по ним.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Интуитивность.`}</strong>{` Стремитесь, чтобы реакции системы на действия  пользователя были предсказуемы. Для этого, к примеру: подписывайте кнопки по их действию (удалить, найти, фильтровать, сохранить), на страницах загрузках сообщайте, что сейчас происходит, оповещения об ошибках должны содержать текст с решением проблемы, ссылки внавигации должны иметь название соответствующее содержанию страницы.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Консистентность.`}</strong>{` Продукты ВШЭ должны использовать айдентику бренда от продукта кпродукту: цвета, иконки, иллюстрации, типографика. Паттерны и принципы взаимодействия с интерфейсом также должны быть одинаковыми во всех продуктах ВШЭ.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Минимализм.`}</strong>{` Соблюдайте чистоту и ясность интерфейса. Не перегружайте экраны активными элементами. Прячьте функционал, которым будут пользоваться реже. Показывайте то, чем будут пользоваться чаще. Стремитесь, чтобы один экран выполнял одну конкретную цель пользователя.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "редполитика"
    }}>{`Редполитика`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Цель и польза.`}</strong>{` Любой элемент интерфейса будь-то кнопка, форма или контрол должен иметь цель использования, а текст — тем более. Решайте с помощью текста конкретные задачи: подсказывайте, доносите важную информацию, убеждайте. Не добавляйте текст, если он не несёт пользы.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Простота.`}</strong>{` Продуктами ВШЭ пользуются люди с разным уровнем возможностей и знаний о продуктах нашей экосистемы. Не используйте аббревиатуры или расшифровывайте их. Не используйте сложные, профессиональные, используемые в узких кругах термины — заменяйте на простые синонимы, либо не используйте совсем.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Краткость.`}</strong>{` Пользователь пропускает большие куски текста, так как в процессе взаимодействия с интерфейсом он хочет решить свою задачу, а не читать. Поэтому используйте минимум текста для донесения необходимой информации.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Вежливость.`}</strong>{` В коммуникации с пользователями через текст нам важно строить приветливую, вежливую, но в то же время серьёзную атмосферу. Поэтому в обращении к пользователю используйте местоимение «вы». В просьбах в интерфейсе используйте «пожалуйста», а в приветствиях «Здравствуйте» и так далее.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "ссылки"
    }}>{`Ссылки`}</h2>
    <ul>
  <li>
    <Link subdomain="figma" href="" mdxType="Link">Figma</Link>
  </li>
  <li>
    <Link subdomain="storybook" href="" mdxType="Link">React Storybook</Link>
  </li>
  <li>
    <Link subdomain="vue-design" href="" mdxType="Link">Документация компонентов Vue</Link>
  </li>
  <li>
    <Link subdomain="vue-storybook" href="" mdxType="Link">Vue Storybook</Link>
  </li>
    </ul>
    <h2 {...{
      "id": "с-чего-начать"
    }}>{`С чего начать?`}</h2>
    <p>{`Если вы дизайнер нового продукта, то начните с `}<a parentName="p" {...{
        "href": "/setup-design"
      }}>{`гайда для дизайнеров`}</a>{`.`}</p>
    <p>{`Если вы разработчик продукта, то переходите к `}<a parentName="p" {...{
        "href": "/installation"
      }}>{`инструкции по установке`}</a>{`.`}</p>
    <p>{`Используете Дизайн-систему в своем продукте ВШЭ?
`}<a parentName="p" {...{
        "href": "/contacts"
      }}>{`Напишите нам`}</a>{` для оперативной поддержки.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      